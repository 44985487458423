import { useCallback, useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import { apiService } from '../../../services/api-service';
import { type UpdateSettingsDataReq } from '../../../services/api-service/settings.api';
import {
  type SettingsData,
  type SettingsKey,
  type SettingsName,
} from '../../../types/settings';

interface useSettingsDataReturned<Name extends SettingsName> {
  data?: SettingsData<Name>;
  isLoading: boolean;
  error?: Error | null;
  updateValue: <Key extends SettingsKey<Name>>(
    req: UpdateSettingsDataReq<Name, Key>
  ) => Promise<void>;
}

export function useSettingsData<Name extends SettingsName>(
  name: Name
): useSettingsDataReturned<Name> {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    `/settings/${name}/data`,
    async () => (await apiService.settings.getData(name)).data.data
  );

  const updateValue = useCallback(
    async <Key extends SettingsKey<Name>>(
      req: UpdateSettingsDataReq<Name, Key>
    ) => {
      await apiService.settings.updateValue(name, req);
      mutate();
    },
    [mutate, name]
  );

  return useMemo(
    () => ({
      data: data,
      isLoading,
      error,
      updateValue,
    }),
    [data, isLoading, error, updateValue]
  );
}
